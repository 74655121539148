<template>
    <div>
        <div v-if="state === 'saving'" class="text-center">
            <b-spinner large v-if="state === 'saving'"></b-spinner>
        </div>
        <div v-if="state === 'show'">
            <b-row v-for="(item, categoryIndex) in selectedChecklist.categories" :key="categoryIndex" >
                <b-col cols="12" class="text-center m-0 p-0">
                    <h4 class="text-left">{{item.description}}</h4>
                    <img v-if="item.imageFile.base64Data !== null" :src="returnFileObject(item.imageFile)" alt=""
                    class="checklist-images" @click="openModalImage(item.imageFile)">
                </b-col>
                <b-col cols="12" class="m-0 p-0">

                    <b-row class="m-0 p-0" v-for="(q, qIndex) in item.questions" :key="qIndex">
                        <img v-if="q.imageFile !== null" :src="returnFileObject(q.imageFile)" alt=""
                        class="checklist-images" @click="openModalImage(q.imageFile)">
                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Text'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-input v-model="q.answer" @input="validateAnswer(q)"></b-form-input>
                            <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                        </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Number'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-input v-model="q.answer" type="number" @input="validateAnswer(q)"></b-form-input>
                            <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                        </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Image'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-file
                                class="mt-1"
                                multiple
                                accept="image/*"
                                v-model="q.imageFile"
                                @change="((e) => {
                                    imageAnswerUploaded(q, e)
                                })"
                                placeholder="Choose a file..."
                                drop-placeholder="Drop file here..."
                                
                            ></b-form-file>
                            <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                            <p class="text-center" v-if="q.loading > 0">({{q.loading}}) Being uploaded</p>
                            <b-badge v-for="(item, index) in q.answerImages" :key="index" variant="success" class="m-1">
                                {{item.documentName}}
                                <a @click="removeImageFromQuestion(q, item.documentId)"> <b-icon-x-circle ></b-icon-x-circle></a>
                            </b-badge>
                        </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'YesNo'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>

                            <b-form-radio-group v-model="q.answer" @change="validateAnswer(q)">
                                <b-form-radio value="Yes">Yes</b-form-radio>
                                <b-form-radio value="No">No</b-form-radio>
                            </b-form-radio-group>
                            <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                        </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'LongText'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-textarea
                                class="pt-2"
                                rows="2"
                                size="sm"
                                v-model="q.answer"
                                @input="validateAnswer(q)"
                            ></b-form-textarea>
                            <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                        </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'DateTime'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-datepicker v-model="q.answer" @change="validateAnswer(q)"></b-form-datepicker>
                            <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                        </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Date'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-datepicker v-model="q.answer" @change="validateAnswer(q)"></b-form-datepicker>
                            <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                        </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Time'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-timepicker v-model="q.answer" locale="en" @input="validateAnswer(q)"></b-form-timepicker>
                            <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                        </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'SingleSelect'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-select v-model="q.answer" @change="answerSelected(q)">
                                <b-form-select-option v-for="(item, index) in q.availableAnswers" :key="index" :value="item.acceptableAnswer">{{item.acceptableAnswer}}</b-form-select-option>
                            </b-form-select>
                            <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                        </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'MultiSelect'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-checkbox-group class="mb-2">
                                <b-input-group-text is-text style="background: transparent;">
                                <b-form-checkbox class='multiselect-checkox' v-model="q.answer" @change="answerSelected(q)"
                                v-for="(item, index) in q.availableAnswers" :key="index" :value="item.acceptableAnswer" name="" unchecked-value=""
                               
                                >
                                {{item.acceptableAnswer}}
                                </b-form-checkbox>
                                </b-input-group-text>
                                <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                             </b-form-checkbox-group>
                         </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'RadioButton'">
                            <label>{{q.question}}</label>
                            <b-form-radio-group>
                                <b-form-radio class="radio-col" v-model="q.answer" @change="answerSelected(q)" v-for="(item, index) in q.availableAnswers" :key="index" :value="item.acceptableAnswer">
                                {{item.acceptableAnswer}}
                                </b-form-radio>
                            </b-form-radio-group>
                        </b-col>

                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'MultiLevel'">
                            <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-select v-model="q.answer"  @change="answerSelected(q)">
                                <b-form-select-option v-for="(item, index) in q.availableAnswers" :key="index" :value="item.acceptableAnswer">{{item.acceptableAnswer}}</b-form-select-option>
                            </b-form-select>
                            <p v-if="q.answer !== null">Choose one of the following:</p>

                            <b-form-select v-model="q.subAnswer" @change="answerSelected(q)" v-if="q.answer !== null">
                                <b-form-select-option v-for="(item, index) in optionAnswers(q)" :key="index" :value="item.acceptableAnswer">{{item.acceptableAnswer}}</b-form-select-option>
                            </b-form-select>
                            <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span>
                        </b-col>

                        <b-col cols="12" class="m-0 p-1 background-offset" v-if="q.isException !== null && q.isException">
                            <label class="bold">Notes  <span v-if="q.required" class="text-red">*</span></label>
                            <b-form-textarea
                                class="pt-2"
                                rows="2"
                                size="sm"
                                v-model="q.answerNotes"
                                @input="validateAnswer(q)"
                            ></b-form-textarea>
                            <p>Add an image</p>
                            <b-form-file
                                class="mt-1"
                                multiple
                                accept="image/*"
                                v-model="q.imageFile"
                                @change="((e) => {
                                    filesUploaded(q, e)
                                })"
                                placeholder="Choose a file..."
                                drop-placeholder="Drop file here..."
                               
                            ></b-form-file>
                            <p class="text-center" v-if="q.loading > 0">({{q.loading}}) Being uploaded</p>
                            <b-badge v-for="(item, index) in q.answerImages" :key="index" variant="success" class="m-1">
                               {{q.documentName}}
                                <a @click="removeImageFromQuestion(q, item.documentId)"> <b-icon-x-circle ></b-icon-x-circle></a>
                            </b-badge>
                        </b-col>

                    </b-row>

                </b-col>
            </b-row>


            <hr  />
            <b-row class="">
                <b-col cols="12" class="m-0 p-0">
                    <b-button block variant="primary" @click="completeChecklist()">
                        Done</b-button>
                </b-col>
            </b-row>

            <b-modal id="show-image-modal" hide-footer size="xl" no-header @close="closeSearchModal()">
                <b-row>
                    <b-col>
                        <img v-if="modalImage.base64Data !== null" :src="returnFileObject(modalImage)" alt=""
                            class="modal-images">
                        <p class="text-center" v-if="modalImage.base64Data === null" >Could not load image</p>
                    </b-col>
                </b-row>
            </b-modal>

        </div>
    </div>
</template>


<script>
import { bus } from '../main'
import { mapActions, mapState } from 'vuex'
export default {
    data:() => ({
        state: 'show',
        formErrors: [],
        modalImage: {
            base64Data: null
        }
    }),
    created(){
        for (let i = 0; i < this.selectedChecklist.categories.length; i++) {
            const element = this.selectedChecklist.categories[i];
            for (let j = 0; j < element.questions.length; j++) {
                const questionElement = element.questions[j];
                questionElement.isException = false
                questionElement.answerNotes = ''
                questionElement.imageFile = null
                questionElement.answerImages = []
                questionElement.loading = 0
                questionElement.answer = null
                questionElement.subAnswer = null
            }
        }
    },
    methods: {
        ...mapActions(['saveUserChecklist', 'saveJobCardChecklist', 'uploadAnswerImage']),
        ...mapActions('notificationService', ['addErrorMessage', 'addWarningMessage', 'addSuccessMessage', 'clearMessage']),
        optionAnswers(question) {
            let indexOfAnswer = question.availableAnswers.findIndex(element => element.acceptableAnswer === question.answer)

            return question.availableAnswers[indexOfAnswer].multiLevelAcceptableAnswers
        },
        imageAnswerUploaded(q,e){
            for (let i = 0; i < e.target.files.length; i++) {
                const element = e.target.files[i];
                if (element.size > 20000000) {
                        //console.log('Document to big to be uploaded')
                        this.errors.push({message: 'Document to big to be uploaded'})
                    return
                }
            }
            this.createBase64ImageFile(q, e)
            q.imageFile = null
        },
        removeImageFromQuestion(q, item){
            this.state = 'loading'
            let itemIndex = q.answerImages.findIndex(element => element.documentId === item)
            q.answerImages.splice(itemIndex, 1)
            this.state = 'show'
        },
        createBase64ImageFile(q, fileList){
            for (let i = 0; i < fileList.target.files.length; i++) {
                const element = fileList.target.files[i];
                const reader = new FileReader()
                q.loading++

                reader.onload = (e) => {
                    let file = e.target.result
                    let request = {
                        documentName: element.name,
                        base64Data: file,
                        description: element.name
                    }

                    this.$store.commit('setUploadAnswerImageRequest', request)
                    this.uploadAnswerImage()
                    .then((res) =>{
                        this.state = 'loading'
                        q.answerImages.push({
                            documentName: element.name,
                            documentId: res.data.result
                        })
                        q.loading--
                        this.state = 'show'
                    })
                    .catch(() => {
                        q.loading--
                    })
                }

                reader.readAsDataURL(element)
            }

        },
        filesUploaded(q, e){
            for (let i = 0; i < e.target.files.length; i++) {
                const element = e.target.files[i];
                if (element.size > 20000000) {
                        //console.log('Document to big to be uploaded')
                        this.errors.push({message: 'Document to big to be uploaded'})
                    return
                }
            }
            this.createBase64File(q, e)
            q.imageFile = null
        },
        createBase64File(q, fileList){
            
            for (let i = 0; i < fileList.target.files.length; i++) {
                const element = fileList.target.files[i];
                const reader = new FileReader()
                q.loading++

                reader.onload = (e) => {
                    let file = e.target.result
                    let request = {
                        documentName: element.name,
                        base64Data: file,
                        description: element.name
                    }
                    
                    this.$store.commit('setUploadAnswerImageRequest', request)
                    this.uploadAnswerImage()
                    .then((res) =>{
                        this.state = 'loading'
                        q.answerImages.push({documentId: res.data.result})
                       q.documentName = request.documentName
                        this.state = 'show'
                        q.loading--
                    })
                    .catch(err=>{
                        console.log("upload error",err)
                        q.loading--
                    })
                    
                }

                reader.readAsDataURL(element)
            }

        },
        answerSelected(question){
            this.state = 'loading'

            if (question.answerType === 'MultiSelect') {
                const items = question.answer.map(element => question.availableAnswers.find(answer => answer.acceptableAnswer === element && answer.isException === true)).filter(Boolean);
                question.isException = items.length !== 0
            } else if (question.answerType !== 'MultiLevel') {
                let item = question.availableAnswers.find(element => element.acceptableAnswer === question.answer)
                question.isException = item.isException
            } else if (question.subAnswer !== null) {
                let item = question.availableAnswers.find(element => element.acceptableAnswer === question.answer)
                let itemAnswer = item.multiLevelAcceptableAnswers.find(element => element.acceptableAnswer === question.subAnswer)
                
                if (itemAnswer !== undefined) question.isException = itemAnswer.isException
                else question.isException = false
            } else {
                question.isException = false
            }

            this.validateAnswer(question);
            this.state = 'show'
        },
        openModalImage(image){
            this.modalImage = image
            this.$root.$emit('bv::show::modal', 'show-image-modal', '#btnShow')
        },
        returnFileObject(item){
            let base64 = 'data:' + item.contentType +';base64,'+ item.base64Data
            return base64
        },
        isEmpty(value) {
            if (typeof value === 'string') {
                return value.trim() === '';
            } else if (Array.isArray(value) && value.length === 0) {
                return true;
            }

            return false;
        },
        validateAnswer(changedQuestion) {
            const result = this.formErrors.find(q => q.questionId === changedQuestion.questionId);

            let error = null;

            if (changedQuestion.isException) {
                if (changedQuestion.answer && (!changedQuestion.answerNotes || this.isEmpty(changedQuestion.answerNotes))) {
                    error = `Please enter a note for the question: ${changedQuestion.question}`;

                    if (!result) {
                        this.formErrors.push({ questionId: changedQuestion.questionId, error:  error});
                    }
                }
            }

            if (changedQuestion.required && changedQuestion.answerType == 'MultiLevel') {
                if (!changedQuestion.subAnswer || this.isEmpty(changedQuestion.subAnswer)) {
                    error = `Please enter an answer for the question: ${changedQuestion.question}`;
                    if (!result) {
                        this.formErrors.push({ questionId: changedQuestion.questionId, error:  error});
                    }
                } 

            } else if (changedQuestion.required && (!changedQuestion.answer || this.isEmpty(changedQuestion.answer))) {
                if (changedQuestion.answerType !== 'Image' 
                && changedQuestion.answer 
                && typeof changedQuestion.answer == 'string' 
                && changedQuestion.answer.length > 8000) {
                    error = `Please shorten the answer for the question: ${changedQuestion.question}`;
                } else if (changedQuestion.answerType === 'Image') {
                    if (changedQuestion.answerImages.length < 1) {
                        error = `Please enter an answer for the question: ${changedQuestion.question}`;
                    }
                } else {
                    error = `${changedQuestion.question} is required`;
                }

                if (!result) {
                    this.formErrors.push({ questionId: changedQuestion.questionId, error:  error});
                }
            }

            if (result) {
                result.error = error;
            }
        },
        validateTextAnswer(item){
            if (item.answer === null && item.required === true) {
                return false
            }

            if (item.availableAnswers.length > 0 && item.answer !== null) {
                let foundAnswer = false
                for (let i = 0; i < item.availableAnswers.length; i++) {
                    const element = item.availableAnswers[i];
                    if (item.answer === element.acceptableAnswer) {
                        foundAnswer = true
                    }
                }
                if (foundAnswer === false) {
                    return false
                }
            }
            return null
        },
        hasError(q) {
            return this.formErrors.some(e => e.questionId === q.questionId);
        },
        getError(q) {
            return this.formErrors.find(e => e.questionId === q.questionId).error;
        },
        isFormValid() {
            return this.formErrors.filter(question => question.error !== null).length === 0;
        },
        completeChecklist() {
            this.clearMessage()
            let requestList = []

            this.selectedChecklist.categories.forEach(element => {
                element.questions.forEach(question => {
                    this.validateAnswer(question);

                    requestList.push({"checkListQuestionID": question.questionId, "answer": question.answer, 'answerImages' : question.answerImages, 'answerNotes' : [question.answerNotes], 'subAnswer': question.subAnswer})
                });
            });

            requestList.forEach(element => {
                if (Array.isArray(element.answer)) {
                    element.answer = element.answer.join('||')
                }
            });

            if (this.isFormValid()) {
                this.state = 'saving'

                if (this.selectedChecklist?.ticketId) {
                    let request = {
                        "checkListId": this.selectedChecklist.checkListId,
                        "ticketId": this.selectedChecklist.ticketId,
                        "completed": true,
                        "description": this.selectedChecklist.description,
                        "questionareType": this.selectedChecklist.questionareType,
                        "checkListType": this.selectedChecklist.checkListType,
                        "checkListQuestions": requestList
                    }

                    this.$store.commit('setJobcardChecklistRequest', request)
                    this.saveJobCardChecklist()
                    .then(() => {
                        this.showSuccessMessage('');
                        const checkListToUpdate = this.selectedJobCard.checkLists.find(checkList => 
                            checkList.checkListId === request.checkListId && checkList.ticketId === request.ticketId
                        );

                        if (checkListToUpdate) {
                            checkListToUpdate.completed = true;
                            checkListToUpdate.checkListQuestions = requestList.checkListQuestions;
                        }

                        this.$emit('complete-checklist-request');
                    })
                    .catch((err) => {
                        this.state = 'show'
                        console.log("Error",err);
                    })
                }
                else {
                    let request = {
                        "checkListId": this.selectedChecklist.checkListId,
                        "checkListScheduleID": this.scheduleId !== null ? this.scheduleId : null,
                        "userCheckListAnswers": requestList
                    }

                    this.$store.commit('setUserChecklistRequest', request)
                    this.saveUserChecklist()
                    .then((res) => {
                        console.log('res.data',res.data)
                        var msg = res.data.item2;
                        if (msg!="")
                        {
                            msg = "New Ticket created with reference : " + msg + "";
                        }
                        this.showSuccessMessage(msg);
                    })
                    .catch(() => {
                        this.state = 'show'
                    })
                }
            }
            else{
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            }
        },
        showSuccessMessage(msg) {
            if (msg==""){msg = 'Completed! Thank you.'}
            this.addSuccessMessage(msg)
                    this.$store.commit('setSelectedChecklist', null)
                    bus.$emit('changeIt')
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                    this.state = 'show'
        }
    },
    computed:{
    ...mapState('notificationService', ['messages']),
    ...mapState(['selectedJobCard', 'selectedChecklist', 'scheduleId', 'checklistSelectionType'])
    }
}
</script>